import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Modal, Button, Form, InputGroup, Row, Col, ListGroup, Card, Alert  } from 'react-bootstrap';
import { FaMapMarkerAlt, FaCalendarAlt, FaTrashAlt, FaUser, FaChild, FaPlus, FaMinus, FaList, FaPlane } from 'react-icons/fa';
import countries from '../data/countries.json';
import './VuelosAereos.css';

const VuelosAereos = () => {
  const [showModal, setShowModal] = useState(false);
  const urlApi = "https://viajescabitours.com.ve/vuelos/sendMail.php";
  const [showListModal, setShowListModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [formData, setFormData] = useState({
    tipoVuelo: '2',
    clase: '1',
    origen: '',
    destino: '',
    fechaIda: '',
    fechaRegreso: '',
    pasajeros: { adultos: 0, ninos: 0 },
    destinosMultiples: []
  });
  const [newDestino, setNewDestino] = useState({ origen: '', destino: '', fechaIda: '', fechaRegreso: '' });
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showErrorAlert, setShowErrorAlert] = useState(false); 

  const tiposVuelo = [
    { key: "solo ida", value: "1" },
    { key: "ida y vuelta", value: "2" },
    { key: "multiples destinos", value: "3" }
  ];

  const clases = [
    { key: "Económico", value: "1" },
    { key: "Business", value: "2" }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasajerosChange = (type, operation) => {
    setFormData((prevState) => {
      const newValue = operation === 'increment' ? prevState.pasajeros[type] + 1 : prevState.pasajeros[type] - 1;
      return {
        ...prevState,
        pasajeros: { ...prevState.pasajeros, [type]: Math.max(newValue, 0) }
      };
    });
  };

  const handleNewDestinoChange = (e) => {
    const { name, value } = e.target;
    setNewDestino({ ...newDestino, [name]: value });
  };

  const isNewDestinoValid = () => {
    return newDestino.origen !== '' && newDestino.destino !== '' && newDestino.fechaIda !== '' && newDestino.fechaRegreso !== '';
  };

  const handleAddDestino = () => {
    newDestino.origen = filterCodeAirline(newDestino.origen);
    newDestino.destino = filterCodeAirline(newDestino.destino);
    setFormData({
      ...formData,
      destinosMultiples: [...formData.destinosMultiples, newDestino]
    });
    setNewDestino({ origen: '', destino: '', fechaIda: '', fechaRegreso: '' });
  };

  const filterCodeAirline = (name) => {
    const destination = countries.find(dest => dest.name === name);
    return destination ? `${destination.name}-(${destination.code})` : "Destino no encontrado";
  }

  const sendDataRequest = () => {
    setShowModal(false);
    const dataToSend = {
      ...formData,
      origen: filterCodeAirline(formData.origen),
      destino: filterCodeAirline(formData.destino),
      name: name,
      phone: phone,
      email: email
    };
    //console.log('dataToSend', dataToSend)
    axios.post(urlApi, dataToSend)
      .then(response => {
        //console.log(response.data);
        setShowSuccessAlert(true); // Mostrar la alerta de éxito
        setTimeout(() => {
          setShowSuccessAlert(false); // Ocultar la alerta de éxito después de 3 segundos
        }, 3000);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        setShowErrorAlert(true); // Mostrar la alerta de error
        setTimeout(() => {
          setShowErrorAlert(false); // Ocultar la alerta de error después de 3 segundos
        }, 3000);
      });
  }

  const handleRemoveDestino = (index) => {
    const destinosMultiples = [...formData.destinosMultiples];
    destinosMultiples.splice(index, 1);
    setFormData({ ...formData, destinosMultiples });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleSubmit = () => {
    /*console.log('origen1', filterCodeAirline(formData.origen))*/
    setShowModal(true);
  };

  const isFormValid = () => {
    const { tipoVuelo, origen, destino, fechaIda, fechaRegreso, pasajeros } = formData;
    if (tipoVuelo === '1') {
      return origen && destino && fechaIda && pasajeros.adultos > 0;
    } else if (tipoVuelo === '2') {
      return origen && destino && fechaIda && fechaRegreso && pasajeros.adultos > 0 && new Date(fechaRegreso) >= new Date(fechaIda);
    } else {
      return formData.destinosMultiples.length > 0 && pasajeros.adultos > 0;
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleCountrySearch = (e) => {
    const { name, value } = e.target;
    const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(filtered);
    setFormData({ ...formData, [name]: value });
  };

  const getFilteredCountries = (exclude) => {
    return countries.filter(country => country.name !== exclude);
  };

  const validateSelection = (e) => {
    const { name, value } = e.target;
    const isValid = countries.some(country => country.name === value);
    if (!isValid) {
      setFormData({ ...formData, [name]: '' });
    }
  };

  useEffect(() => {
    if (formData.tipoVuelo === '1' || formData.tipoVuelo === '2') {
      setFormData((prevState) => ({
        ...prevState,
        destinosMultiples: []
      }));
    }
  }, [formData.tipoVuelo]);

  return (
    <div className="container mt-5">
      {showSuccessAlert && (
        <Alert variant="success" className="custom-alert">
          Solicitud enviada con éxito, responderemos a la brevedad posible.
        </Alert>
      )}
      {showErrorAlert && (
        <Alert variant="danger" className="custom-alert">
          Error al enviar la solicitud. Por favor, inténtelo de nuevo.
        </Alert>
      )}
      <div className="d-flex justify-content-between flex-column-700">
        <div>
          {tiposVuelo.map((tipo) => (
            <Form.Check
              inline
              key={tipo.value}
              type="radio"
              label={tipo.key}
              name="tipoVuelo"
              value={tipo.value}
              checked={formData.tipoVuelo === tipo.value}
              onChange={handleInputChange}
            />
          ))}
        </div>
        <Form.Select
          name="clase"
          value={formData.clase}
          onChange={handleInputChange}
          className="w-auto"
        >
          {clases.map((clase) => (
            <option key={clase.value} value={clase.value}>
              {clase.key}
            </option>
          ))}
        </Form.Select>
      </div>
      {formData.tipoVuelo != '3' && (
        <><Row className="mt-3 flex-column-700">
          <Col>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                <Form.Control
                  type="text"
                  className="rounded-right"
                  placeholder="Origen"
                  name="origen"
                  value={formData.origen}
                  list="countryListOrigen"
                  onBlur={validateSelection}
                  onChange={handleCountrySearch} />
                <datalist id="countryListOrigen">
                  {getFilteredCountries(formData.destino).map((country, index) => (
                    <option key={index} value={country.name} />
                  ))}
                </datalist>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Destino"
                  className="rounded-right"
                  name="destino"
                  value={formData.destino}
                  list="countryListDestino"
                  onBlur={validateSelection}
                  onChange={handleCountrySearch} />
                <datalist id="countryListDestino">
                  {getFilteredCountries(formData.origen).map((country, index) => (
                    <option key={index} value={country.name} />
                  ))}
                </datalist>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row><Row className="mt-3 flex-column-700">
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>Fecha de Ida</InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="Fecha de Ida"
                    name="fechaIda"
                    value={formData.fechaIda}
                    onChange={handleInputChange}
                    min={currentDate}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => e.target.showPicker()} />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              {formData.tipoVuelo !== '1' && (
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Text>Fecha de Regreso</InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="Fecha de Regreso"
                      name="fechaRegreso"
                      value={formData.fechaRegreso}
                      onChange={handleInputChange}
                      min={formData.fechaIda || currentDate}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={(e) => e.target.showPicker()} />
                  </InputGroup>
                </Form.Group>
              )}
            </Col>
          </Row></>
      )}

      {formData.tipoVuelo === '3' && (
        <>
          <Row className="mt-3">
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Origen"
                    name="origen"
                    value={newDestino.origen}
                    list="countryListOrigen"
                    onBlur={validateSelection}
                    onChange={handleNewDestinoChange}
                    className="form-control rounded-right"
                  />
                  <datalist id="countryListOrigen">
                    {getFilteredCountries(newDestino.destino).map((country, index) => (
                      <option key={index} value={country.name} />
                    ))}
                  </datalist>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Destino"
                    name="destino"
                    value={newDestino.destino}
                    list="countryListDestino"
                    onBlur={validateSelection}
                    onChange={handleNewDestinoChange}
                    className="form-control rounded-right"
                  />
                  <datalist id="countryListDestino">
                    {getFilteredCountries(newDestino.origen).map((country, index) => (
                      <option key={index} value={country.name} />
                    ))}
                  </datalist>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text><FaCalendarAlt />Fecha de Ida</InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="Fecha de Ida"
                    name="fechaIda"
                    value={newDestino.fechaIda}
                    onChange={handleNewDestinoChange}
                    min={currentDate}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => e.target.showPicker()}
                    className="form-control rounded-right"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text><FaCalendarAlt />Fecha de Regreso</InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="Fecha de Regreso"
                    name="fechaRegreso"
                    value={newDestino.fechaRegreso}
                    onChange={handleNewDestinoChange}
                    min={formData.fechaIda || currentDate}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => e.target.showPicker()}
                    className="form-control rounded-right"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              className="mt-3 btn-sm"
              onClick={handleAddDestino}
              disabled={!isNewDestinoValid()}
            >
              <FaPlus /> Añadir destino
            </Button>
            <Button
              variant="primary"
              disabled={formData.destinosMultiples.length <= 0}
              className="mt-3 btn-sm ms-2"
              onClick={() => setShowListModal(true)}
            >
              <FaList /> Ver listado
            </Button>
          </div>
          {/* <div className="d-none d-md-block">
            <Row className="mt-3">
              {formData.destinosMultiples.map((destino, index) => (
                <Col md={4} key={index}>
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>Destino {index + 1}</Card.Title>
                      <Card.Text>
                        <strong>Origen:</strong> {destino.origen}<br />
                        <strong>Destino:</strong> {destino.destino}<br />
                        <strong>Fecha de Ida:</strong> {destino.fechaIda}<br />
                        <strong>Fecha de Regreso:</strong> {destino.fechaRegreso}
                      </Card.Text>
                      <Button variant="danger" size="sm" onClick={() => handleRemoveDestino(index)}>
                        <FaTrashAlt />
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div> */}
        </>
      )}
      <Form.Group className="mt-3">
        <Row className="mt-3 flex-column-700">
          <Col>
            <InputGroup>
              <InputGroup.Text><FaUser /> Adultos</InputGroup.Text>
              <InputGroup.Text>
                <FaMinus onClick={() => handlePasajerosChange('adultos', 'decrement')} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Adultos"
                name="adultos"
                value={formData.pasajeros.adultos}
                onChange={handlePasajerosChange}
                min="1"
              />
              <InputGroup.Text>
                <FaPlus onClick={() => handlePasajerosChange('adultos', 'increment')} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text><FaChild />Niños</InputGroup.Text>
              <InputGroup.Text>
                <FaMinus onClick={() => handlePasajerosChange('ninos', 'decrement')} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Niños"
                name="ninos"
                value={formData.pasajeros.ninos}
                onChange={handlePasajerosChange}
                min="0"
              />
              <InputGroup.Text>
                <FaPlus onClick={() => handlePasajerosChange('ninos', 'increment')} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      </Form.Group>
      <Button
        variant="primary"
        className="mt-3"
        onClick={handleSubmit}
        disabled={!isFormValid()}
      >
        Solicitar Cotización
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Solicitar Cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese su nombre"
              value={name}
              onChange={handleNameChange}
              isInvalid={name == ''}
            />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese su email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!isEmailValid && email !== ''}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un email válido.
            </Form.Control.Feedback>
            <Form.Label>Telefono</Form.Label>
            <Form.Control
              type="phone"
              placeholder="Ingrese su telefono"
              value={phone}
              onChange={handlePhoneChange}
              isInvalid={phone == ''}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button
            onClick={sendDataRequest}
            disabled={(!isEmailValid &&
              email == '' &&
              phone == '' &&
              name == '')} variant="primary">
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showListModal} onHide={() => setShowListModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Listado de Destinos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData.destinosMultiples.map((destino, index) => (
            <Card className="mb-3" key={index}>
              <Card.Body>
                <Card.Title> ------Reserva {index + 1}------<FaPlane /></Card.Title>
                <Card.Text>
                  <strong>Origen:</strong> {destino.origen}<br />
                  <strong>Destino:</strong> {destino.destino}<br />
                  <strong>Fecha de Ida:</strong> {destino.fechaIda}<br />
                  <strong>Fecha de Regreso:</strong> {destino.fechaRegreso}
                </Card.Text>
                <Button variant="danger" size="sm" onClick={() => handleRemoveDestino(index)}>
                  <FaTrashAlt />
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowListModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VuelosAereos;
