import React, { useState, useEffect } from 'react';
import { FaInstagram, FaPhone, FaTiktok, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import './Footer.css';
import Iata from "./../assets/IATA.png"
import Mintur from "./../assets/MINTUR.png"
import Avavit from "./../assets/AVAVIT.svg"
import logo from "./../assets/LOGO.png"
import Modal from '../components/Modal';
import './Footer.css';
import termsData from '../data/terms.json'; // Importa el JSON con los términos

const Footer = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: [] });

    useEffect(() => {
        // Cargar los datos del JSON en el estado
        setModalContent({
          title: termsData.terms.title,
          content: termsData.terms.content
        });
      }, []);

    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-section">
          <h2>Nuestras Sedes</h2>
          <a href="#link1"><b>Principal: </b>local #04, CC Cabitours, nivel pb Principal de las Delicias, Cabimas 4013, Zulia, Venezuela</a>
          <a href="#link1"><b>CostaMall:</b> Avenida Intercomunal de Cabimas, Av. Intercomunal, Cabimas 4013, Zulia, Venezuela</a>
          <a href="#link1"><b>Maracaibo: </b>Torre de Estacionamiento Clínica Amado, Avenida 3Y, Local Comercial #1 C. 76, Maracaibo 4001, Zulia, Venezuela</a>
        </div>
        <div className="footer-section">
          <h2>Confianza en tus compras</h2>
          <a href="#link4" onClick={handleOpenModal}><abbr title="Términos y condiciones">Términos y condiciones</abbr></a>
          <a href="#link5" onClick={handleOpenModal}><abbr title="Política de Privacidad">Política de Privacidad</abbr></a>
          <a href="#link5"onClick={handleOpenModal} ><abbr title="FQA's">FQA's</abbr></a>
        </div>
        <div className="footer-section">
          <h2>Contactos</h2>
          <a><b><FaPhone/> </b> 0264-2515113</a>
          <a><b><FaWhatsapp/></b> 0424-7105269</a>
          <a><b><FaWhatsapp/></b> 0424-6696474</a>
          <a><b><MdEmail/> </b>cabitoursviajes@gmail.com</a>
          <a><b><MdEmail/> </b>cabitours.costa@gmail.com</a>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-logos">
          <img src={Iata} alt="IATA Logo" className="footer-logo-back" />
          <img src={Mintur} alt="MINTUR Logo" className="footer-logo-back" />
          <img src={Avavit} alt="AVAVIT Logo" className="footer-logo-back" />
        </div>
        <div className="footer-social">
          <a href="https://www.instagram.com/viajescabitours" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> @viajescabitours
          </a>
          <a href="https://www.tiktok.com/@viajescabitours" target="_blank" rel="noopener noreferrer">
            <FaTiktok /> @viajescabitours
          </a>
          <a href="https://www.youtube.com/viajescabitours" target="_blank" rel="noopener noreferrer">
            <FaYoutube /> @viajescabitoursve
          </a>
        </div>
        <div className="footer-main-logo">
          <img src={logo} alt="Company Logo" className="main-logo" />
        </div>
      </div>
      <div><b>IATA NUMBER:</b> 9550235-2 <b>RTN:</b> 05899 <b>VT:</b>2561</div>
      <div className="footer-bottom">
        <p>© Todos los derechos reservados. RIF: J30380527-2</p>
      </div>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={modalContent.title}
        content={modalContent.content}
      />
    </footer>
  );
};

export default Footer;