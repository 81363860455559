import React, { useEffect } from 'react';

const TikTokProfile = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <blockquote 
      class="tiktok-embed" 
      cite="https://www.tiktok.com/@viajescabitours" 
      data-unique-id="viajescabitours" 
      data-embed-from="embed_page" 
      data-embed-type="creator" 
      style={{ width: '100%', maxWidth: '600px' }}> 
          <section> 
              <a target="_blank" 
              href="https://www.tiktok.com/@viajescabitours?refer=creator_embed">
                  @viajescabitours
              </a> 
          </section> 
      </blockquote> 
    </div>
  );
};

export default TikTokProfile;