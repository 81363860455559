import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const destinos = [
  {
    codigo: 'canaima',
    nombre: 'Canaima',
    video: 'https://www.youtube.com/embed/krRTIDOPB7s?si=a2rDrgosQDzB33EJ&autoplay=1',
    detalles: [
      "Boletos aéreos desde la ciudad de origen al destino",
      "Hospedaje en Habitaciones premium con aire acondicionado, TV, Wifi, limpieza, entre otros.",
      "Todas las comidas (desayuno, almuerzo, merienda, cena)",
      "Traslados (aeropuerto-hotel-aeropuerto)",
      "Incluye ciertos paseos"
    ]
  },
  {
    codigo: 'los_roques',
    nombre: 'Los Roques',
    video: 'https://www.youtube.com/embed/vf1kiABNeV0?si=VEL8dvr8f-SGAZZC&autoplay=1',
    detalles: [
      "Boletos aéreos desde la ciudad de origen al destino",
      "Hospedaje en Habitaciones premium con aire acondicionado, TV, Wifi, limpieza, entre otros.",
      "Todas las comidas (desayuno, almuerzo, merienda, cena)",
      "Traslados (aeropuerto-hotel-aeropuerto)",
      "Incluye ciertos paseos"
    ]
  },
  {
    codigo: 'margarita',
    nombre: 'Margarita',
    video: 'https://www.youtube.com/embed/O_sz0GcExgs?si=GuidoFernandoBecerra&autoplay=1',
    detalles: [
      "Boletos aéreos desde la ciudad de origen al destino",
      "Hospedaje en Habitaciones premium con aire acondicionado, TV, Wifi, limpieza, entre otros.",
      "Todas las comidas (desayuno, almuerzo, merienda, cena)",
      "Traslados (aeropuerto-hotel-aeropuerto)"
    ]
  }
];

const Turismos = () => {
  const [destinoSeleccionado, setDestinoSeleccionado] = useState(destinos[0]);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

  const handleSelectChange = (event) => {
    const destino = destinos.find(d => d.codigo === event.target.value);
    setDestinoSeleccionado(destino);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(emailValue.includes('@'));
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const sendDataRequest = () => {
    const data = {
      name,
      email,
      phone,
      destino: destinoSeleccionado.nombre
    };

    fetch('https://viajescabitours.com.ve/vuelos/sendTurismo.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setAlert({ show: true, message: 'Solicitud enviada con éxito, responderemos a la brevedad posible.', variant: 'success' });
      setShowModal(false);
      setTimeout(() => setAlert({ show: false, message: '', variant: '' }), 3000);
    })
    .catch((error) => {
      setAlert({ show: true, message: 'Error al enviar la solicitud. Por favor, inténtelo de nuevo.', variant: 'danger' });
      setShowModal(false);
      setTimeout(() => setAlert({ show: false, message: '', variant: '' }), 3000);
      console.error('Error:', error);
    });
  };

  return (
    <div className="container">
      {alert.show && (
        <Alert variant={alert.variant} dismissible={false}>
          {alert.message}
        </Alert>
      )}
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column align-items-center">
          <select className="form-select" onChange={handleSelectChange} value={destinoSeleccionado.codigo}>
            {destinos.map(destino => (
              <option key={destino.codigo} value={destino.codigo}>
                {destino.nombre}
              </option>
            ))}
          </select>
          <div className="mt-3 d-none d-md-block">
            <iframe
              width="100%"
              height="215"
              src={destinoSeleccionado.video}
              title={destinoSeleccionado.nombre}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ maxWidth: '450px' }}
            ></iframe>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column align-items-center mt-3 mt-md-0">
          <h2>Detalles del Paquete</h2>
          <div style={{ fontSize: '16px', lineHeight: '1.5', textAlign: 'justify' }}>
            {destinoSeleccionado.detalles.map((detalle, index) => (
              <p key={index} style={{ marginBottom: '10px' }}>{detalle}</p>
            ))}
          </div>
          <button className="btn btn-primary mt-3" onClick={() => setShowModal(true)}>Cotizar</button>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Solicitar Cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese su nombre"
              value={name}
              onChange={handleNameChange}
              isInvalid={name === ''}
            />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese su email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!isEmailValid && email !== ''}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un email válido.
            </Form.Control.Feedback>
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="phone"
              placeholder="Ingrese su teléfono"
              value={phone}
              onChange={handlePhoneChange}
              isInvalid={phone === ''}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button
            onClick={sendDataRequest}
            disabled={!isEmailValid || email === '' || phone === '' || name === ''}
            variant="primary"
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Turismos;