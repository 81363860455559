import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, title, content }) => {
  if (!show) {
    return null;
  }

  const formatText = (text) => {
    return text.split(/(\d+\.\s)/).map((part, index) => (
      <React.Fragment key={index}>
        {part.match(/\d+\.\s/) ? <br /> : null}
        {part}
      </React.Fragment>
    ));
  };

  return (
    <div className="modal-new-overlay">
      <div className="modal-new">
        <div className="modal-new-header">
          <h2>{title}</h2>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="modal-new-content">
          {Array.isArray(content) ? (
            content.map((item, index) => (
              <div key={index}>
                <h3>{item.clause}</h3>
                <p>{formatText(item.text)}</p>
              </div>
            ))
          ) : (
            <p>El contenido no está disponible.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;