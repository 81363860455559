import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Modal, Button, Form, InputGroup, Row, Col, Card, Alert } from 'react-bootstrap';
import { FaMapMarkerAlt, FaCalendarAlt, FaTrashAlt, FaUser, FaChild, FaPlus, FaMinus, FaPlane, FaBed } from 'react-icons/fa';
import countries from '../data/countries.json';
import './ReservaHotel.css';

const ReservaHotel = () => {
  const [showModal, setShowModal] = useState(false);
  const urlApi = "https://viajescabitours.com.ve/vuelos/sendReservaHotel.php";
  const [showListModal, setShowListModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [formData, setFormData] = useState({
    clase: '1',
    ciudad: '',
    habitaciones: 0,
    fechaEntrada: '',
    fechaSalida: '',
    pasajeros: { adultos: 0, ninos: 0 },
    nacionalidad: 'Venezuela',
    destinosMultiples: []
  });
  const [newDestino, setNewDestino] = useState({ ciudad: '', habitaciones: 0, fechaEntrada: '', fechaSalida: '' });
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showErrorAlert, setShowErrorAlert] = useState(false); 
  const clases = [
    { key: "Económico", value: "1" },
    { key: "Business", value: "2" }
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePasajerosChange = (type, operation) => {
    setFormData((prevState) => {
      const newValue = operation === 'increment' ? prevState.pasajeros[type] + 1 : prevState.pasajeros[type] - 1;
      return {
        ...prevState,
        pasajeros: { ...prevState.pasajeros, [type]: Math.max(newValue, 0) }
      };
    });
  };
  const handleHabitacionesChange = (operation) => {
    setFormData((prevState) => {
      const newValue = operation === 'increment' ? prevState.habitaciones + 1 : prevState.habitaciones - 1;
      return {
        ...prevState,
        habitaciones: Math.max(newValue, 0)
      };
    });
  };
  const handleNewDestinoChange = (e) => {
    const { name, value } = e.target;
    setNewDestino({ ...newDestino, [name]: value });
  };
  const isNewDestinoValid = () => {
    return newDestino.ciudad !== '' && newDestino.habitaciones > 0 && newDestino.fechaEntrada !== '' && newDestino.fechaSalida !== '';
  };
  const handleAddDestino = () => {
    setFormData({
      ...formData,
      destinosMultiples: [...formData.destinosMultiples, newDestino]
    });
    setNewDestino({ ciudad: '', habitaciones: 0, fechaEntrada: '', fechaSalida: '' });
  };
  const sendDataRequest = () => {
    setShowModal(false);
    const dataToSend = {
      ...formData,
      name: name,
      phone: phone,
      email: email
    };
    axios.post(urlApi, dataToSend)
      .then(response => {
        setShowSuccessAlert(true); // Mostrar la alerta de éxito
        setTimeout(() => {
          setShowSuccessAlert(false); // Ocultar la alerta de éxito después de 3 segundos
        }, 3000);
      })
      .catch(error => {
        setShowErrorAlert(true); // Mostrar la alerta de error
        setTimeout(() => {
          setShowErrorAlert(false); // Ocultar la alerta de error después de 3 segundos
        }, 3000);
      });
  }
  const handleRemoveDestino = (index) => {
    const destinosMultiples = [...formData.destinosMultiples];
    destinosMultiples.splice(index, 1);
    setFormData({ ...formData, destinosMultiples });
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };
  const handleSubmit = () => {
    setShowModal(true);
  };
  const isFormValid = () => {
    const { ciudad, habitaciones, fechaEntrada, fechaSalida, pasajeros, nacionalidad } = formData;
    return ciudad && habitaciones > 0 && fechaEntrada && fechaSalida && pasajeros.adultos > 0 && nacionalidad && new Date(fechaSalida) >= new Date(fechaEntrada);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const handleCountrySearch = (e) => {
    const { name, value } = e.target;
    const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(filtered);
    setFormData({ ...formData, [name]: value });
  };
  const getFilteredCountries = (exclude) => {
    return countries.filter(country => country.name !== exclude);
  };
  const validateSelection = (e) => {
    const { name, value } = e.target;
    const isValid = countries.some(country => country.name === value);
    if (!isValid) {
      setFormData({ ...formData, [name]: '' });
    }
  };
  return (
    <div className="container mt-5">
      {showSuccessAlert && (
        <Alert variant="success" className="custom-alert">
          Solicitud enviada con éxito, responderemos a la brevedad posible.
        </Alert>
      )}
      {showErrorAlert && (
        <Alert variant="danger" className="custom-alert">
          Error al enviar la solicitud. Por favor, inténtelo de nuevo.
        </Alert>
      )}
      <div className="d-flex justify-content-between align-items-center">
      <Form.Group className="mb-0" style={{ maxWidth: '200px' }}>
        <Form.Label>Clase</Form.Label>
          <Form.Select
            name="clase"
            value={formData.clase}
            onChange={handleInputChange}
            className="w-auto me-3"
          >
            {clases.map((clase) => (
              <option key={clase.value} value={clase.value}>
                {clase.key}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-0" style={{ maxWidth: '200px' }}>
          <Form.Label>Nacionalidad</Form.Label>
          <Form.Select
            name="nacionalidad"
            value={formData.nacionalidad}
            onChange={handleInputChange}
          >
            {countries.map((country, index) => (
              <option key={index} value={country.name}>
                {country.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <Row className="mt-3 flex-column-700">
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text><FaMapMarkerAlt />Ciudad</InputGroup.Text>
              <Form.Control
                type="text"
                className="rounded-right"
                placeholder="Ciudad"
                name="ciudad"
                value={formData.ciudad}
                list="countryListCiudad"
                onBlur={validateSelection}
                onChange={handleCountrySearch} />
              <datalist id="countryListCiudad">
                {getFilteredCountries(formData.habitaciones).map((country, index) => (
                  <option key={index} value={country.name} />
                ))}
              </datalist>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text><FaBed />Habitación/es</InputGroup.Text>
              <InputGroup.Text>
                <FaMinus onClick={() => handleHabitacionesChange('decrement')} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Habitaciones"
                name="habitaciones"
                value={formData.habitaciones}
                onChange={handleInputChange}
                min="1"
              />
              <InputGroup.Text>
                <FaPlus onClick={() => handleHabitacionesChange('increment')} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 flex-column-700">
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>Fecha de Entrada</InputGroup.Text>
              <Form.Control
                type="date"
                placeholder="Fecha de Entrada"
                name="fechaEntrada"
              value={formData.fechaEntrada}
              onChange={handleInputChange}
              min={currentDate}
              onKeyDown={(e) => e.preventDefault()}
              onClick={(e) => e.target.showPicker()} />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>Fecha de Salida</InputGroup.Text>
              <Form.Control
                type="date"
                placeholder="Fecha de Salida"
                name="fechaSalida"
                value={formData.fechaSalida}
                onChange={handleInputChange}
                min={formData.fechaEntrada || currentDate}
                onKeyDown={(e) => e.preventDefault()}
                onClick={(e) => e.target.showPicker()} />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 flex-column-700">
        <Col>
          <InputGroup>
            <InputGroup.Text><FaUser /> Adultos</InputGroup.Text>
            <InputGroup.Text>
              <FaMinus onClick={() => handlePasajerosChange('adultos', 'decrement')} />
            </InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Adultos"
              name="adultos"
              value={formData.pasajeros.adultos}
              onChange={handlePasajerosChange}
              min="1"
            />
            <InputGroup.Text>
              <FaPlus onClick={() => handlePasajerosChange('adultos', 'increment')} />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text><FaChild />Niños</InputGroup.Text>
            <InputGroup.Text>
              <FaMinus onClick={() => handlePasajerosChange('ninos', 'decrement')} />
            </InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Niños"
              name="ninos"
              value={formData.pasajeros.ninos}
              onChange={handlePasajerosChange}
              min="0"
            />
            <InputGroup.Text>
              <FaPlus onClick={() => handlePasajerosChange('ninos', 'increment')} />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Button
        variant="primary"
        className="mt-3"
        onClick={handleSubmit}
        disabled={!isFormValid()}
      >
        Solicitar Cotización
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Solicitar Cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese su nombre"
              value={name}
              onChange={handleNameChange}
              isInvalid={name === ''}
            />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese su email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!isEmailValid && email !== ''}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un email válido.
            </Form.Control.Feedback>
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="phone"
              placeholder="Ingrese su teléfono"
              value={phone}
              onChange={handlePhoneChange}
              isInvalid={phone === ''}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button
            onClick={sendDataRequest}
            disabled={!isEmailValid || email === '' || phone === '' || name === ''}
            variant="primary"
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showListModal} onHide={() => setShowListModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Listado de Destinos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData.destinosMultiples.map((destino, index) => (
            <Card className="mb-3" key={index}>
              <Card.Body>
                <Card.Title> ------Reserva {index + 1}------<FaPlane /></Card.Title>
                <Card.Text>
                  <strong>Ciudad:</strong> {destino.ciudad}<br />
                  <strong>Habitaciones:</strong> {destino.habitaciones}<br />
                  <strong>Fecha de Entrada:</strong> {destino.fechaEntrada}<br />
                  <strong>Fecha de Salida:</strong> {destino.fechaSalida}
                </Card.Text>
                <Button variant="danger" size="sm" onClick={() => handleRemoveDestino(index)}>
                  <FaTrashAlt />
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowListModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReservaHotel;