import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Modal, Button } from 'react-bootstrap';
import './Sucursals.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SucursalsData from '../data/sucursals.json';
import Principal from '../assets/Sede_Principal.jpg';
import Maracaibo from '../assets/Sucursal_Maracaibo.jpg';
import Costa from '../assets/Sucursal_Costa.jpg';

const Sucursals = () => {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentMapUrl, setCurrentMapUrl] = useState('');
  const sliderRef = useRef(null);

  useEffect(() => {
    // fetch(sucursals)
    //   .then(response => response.json())
    //   .then(data => setImages(data))
    //   .catch(error => console.error('Error loading images:', error));
      setImages(SucursalsData)
      const timer = setTimeout(() => {
        const dots = document.querySelectorAll('.slick-dots');
        if (dots.length > 2) {
          const buttons = dots[2].querySelectorAll('li button');
          if (buttons.length > 0) {
            buttons[0].click(); // Simula un clic en el primer botón de los puntos de navegación
          }
        }
      }, 1000); // Espera 1 segundo para asegurarse de que el DOM esté listo
  
      return () => clearTimeout(timer); // Limpia el 
  }, []);

  const handleViewMap = (mapUrl) => {
    setCurrentMapUrl(mapUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentMapUrl('');
  };

  const imgMap = {
    Principal: Principal,
    Maracaibo: Maracaibo,
    Costa:Costa,
  };
  

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="image-gallery">
              <h4 className="text-content-init color-text-init"><b>Visítanos en Cualquiera de Nuestras Sedes</b></h4>

      <Slider {...settings}>
        {images.map((image, index) => (
          <div className="image-container" key={index}>
            <img src={imgMap[image.src]} alt={image.alt} className="gallery-image" />
            <div className="view-map-button" >
            <Button 
              variant="primary"
              onClick={() => handleViewMap(image.mapUrl)}
              className="button-maps-link"
            >
              Ver mapa
            </Button>
            </div>
          </div>
        ))}
      </Slider>
      <Modal show={modalIsOpen} size="sm" class="modal-new" onHide={closeModal} centered>
      <Button variant="outline-secondary" onClick={closeModal} className="close-modal-button">Cerrar</Button>
        <iframe
          src={currentMapUrl}
          width="360px"
          height="510px"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Modal>
    </div>
  );
};


export default Sucursals;