import { React, useEffect } from 'react';
import './App.css';
import bannerImage from './assets/banner.jpg';
import logo from './assets/LOGO.png';
import logoWhatsapp from './assets/logo-whatsapp.jpg';
// import YouTube from 'react-youtube';
import { FaInstagram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
//import Slider from 'react-slick';
import InstagramFeed from './components/InstagramFeed';
import InstagramProfile from './components/InstagramProfile';
import FeaturesSection from './components/FeaturesSection';
import TermsAndConditions from "./components/TermsAndConditions";
//import YoutubeChannel from './components/YoutubeChannel';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import TikTokVideos from './components/TikTokVideos';
import Sucursals from './components/Sucursals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaXTwitter } from 'react-icons/fa6';

import ReservationBar from './components/ReservasBarra';


// Imágenes de servicios
/*import service1 from './assets/service1.jpg';
import service2 from './assets/service2.jpg';
import service3 from './assets/service3.jpg';*/
/* const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};*/
const keyU = "U1VkUlYxSlBZMFZqTUdGVVpHeFBWekZGVVROU1JXVnJZekZUTTFaT1UwaE5lbFV4YkZoUFZ6bDVZbFprV2tGT2JtaERWakZ3Y1ZkVlNtMVRhbXQzVlhwT2NtTkhUbFprTUU1cFZHdFNRMVpWVG5wa01HdzJUMGRTZFZGVll6VlRNMWt5WVZo"
const keyD = "R1RGZFZVa3hpUmpneFVXczVVbU42VWxOaU1UbEdXa0V3UmxsaFJ6RjJVMGh3Tm1ScVFrdFJWRUpzVFVkU2QxUXhXVEJVYTAwMFdERkNiVlZ1VFZwRQ=="
const instagramToken = atob(keyU+keyD);
//process.env.REACT_APP_TOKEN_INSTAGRAM; // Reemplaza con tu token de acceso
const instagramHashtag = 'webcabitours';
const userId = atob("MTc4NDE0MDA4NTEyNzE0MDY=");
const channelId = 'UCqvdq099waLoFRK4GBJH38g'; 

function App() {
  useEffect(() => {
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="banner">
          <img src={bannerImage} alt="Paisaje de Venecia" className="banner-image" />
          <img src={logo} alt="Logo" className="logo" />
          <div>
            <ReservationBar />
          </div>
          <div className="social-icons">
            <a href="https://www.instagram.com/viajescabitours" target="_blank" rel="noopener noreferrer">
              <FaInstagram size="20"/>
            </a>
            <a href="https://www.tiktok.com/@viajescabitours" target="_blank" rel="noopener noreferrer">
              <FaTiktok size="20"/>
            </a>
            <a href="https://www.youtube.com/channel/UCqvdq099waLoFRK4GBJH38g" target="_blank" rel="noopener noreferrer">
              <FaYoutube size="20" />
            </a>
            <a href="https://x.com/viajescabitours" target="_blank" rel="noopener noreferrer">
              <FaXTwitter size="20" />
            </a>
          </div>
        </div>
        <div className="instagram-feed-content">
        <div>
        <InstagramFeed token={instagramToken} hashtag={instagramHashtag} />
        </div>
        </div>
        <h2 className="text-content-init color-text-init"><b>Síguenos En Nuestras Redes Sociales</b></h2>
        <div className="social-feeds">
          <div className="youtube-feed">
            <h2><FaTiktok/>TIKTOK</h2>
            {/* <YoutubeChannel channelId={channelId}/> */}
             <TikTokVideos/>
            {/*<div className="youtube-feed-container">
              
               <YouTube videoId="krRTIDOPB7s" /> 
            </div>*/}
            {/* <div className="youtube-feed-container">
              <YouTube videoId="AMq90OcXI9g" />
            </div> */}
            {/* Agrega más videos de YouTube aquí */}
          </div>
          <div className="instagram-feed-right">
            <h2><FaInstagram/>INSTAGRAM</h2>
            <InstagramProfile accessToken={instagramToken} userId={userId} />

          </div>
        </div>
        <div className="services-slider">
          {/* <ProductsAndServices /> */}
          <FeaturesSection />
        </div>
        <div className="sucursals">
          <Sucursals/>
        </div>
        <div className="map-form-container">
          <div className="map-container">
          {/* <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.2704533138253!2d-71.45597822590013!3d10.40008116602463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e89c7318e6445f5%3A0x8d72cc4d2367b328!2sAgencia%20de%20Viajes%20Cabitours!5e0!3m2!1ses!2scl!4v1723599508649!5m2!1ses!2scl" 
          width="600" 
          height="450" 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
          </iframe> */}
          {/* <Sucursal/> */}
          <p class="text-justify colorText">Completa el formulario con tus datos, detallando las características de tu viaje o inquietudes y pronto nuestros agentes de viajes le estarán contactando.</p>
          <TermsAndConditions/>
          <p class="text-justify font-italic colorText">**Es importante completar todos los datos y que la información sea lo más detallada posible para así brindarle una respuesta adecuada a su solicitud.**</p>
          </div>
          <div className="form-container">
            <ContactForm />
            {/* <form>
              <input type="text" placeholder="Nombre" required />
              <input type="email" placeholder="Email" required />
              <input type="tel" placeholder="Teléfono" required />
              <textarea placeholder="Comentario" rows="4" required></textarea>
              <button type="submit">Enviar</button>
            </form> */}
          </div>
        </div>
      </header>
      <footer className="App-footer">
        {/* <div className="footer-content">
          <p>&copy; 2024 Viajes Cabitours. Todos los derechos reservados.</p>
          <p>&copy; 2024 Viajes Cabitours. Todos los derechos reservados.</p>
          <img src={logo} alt="Logo" className="footer-logo" />
        </div> */}
        <Footer/>
      </footer>
      <FloatingWhatsApp 
        avatar={logoWhatsapp} 
        phoneNumber='+584246696474'
        accountName="Viajes Cabitours"
        statusMessage="tiempo aproximado de respuesta 20 minutos"
        chatMessage="Hola! gracias por visitar nuestra web, indicanos ¿cual es tu requerimiento?"
        allowEsc
        allowClickAway
        chatboxHeight={370} 
        notification
        notificationSound />
      {/* <div className="whatsapp-float">
        <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
      </div> */}
    </div>
  );
}

export default App;
