import React, { useState } from 'react';
import './ReservasBarra.css';
import VuelosAereos from './VuelosAereos';
import AlquilerAuto from './AlquilerAuto';
import ReservaHotel from './ReservaHotel';
import Traslados from './Traslados';
import Turismos from './Turismos';

const ReservationForm = () => {
  const [activeTab, setActiveTab] = useState('flights');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes implementar la lógica para enviar el formulario
  };

  return (
    <div className="reservation-container">
      <div className="tabs">
        <button className={activeTab === 'flights' ? 'active' : ''} onClick={() => setActiveTab('flights')}>
          <i className="fas fa-plane-departure"></i> Vuelos Aéreos
        </button>
        <button className={activeTab === 'carRentals' ? 'active' : ''} onClick={() => setActiveTab('carRentals')}>
          <i className="fas fa-car"></i> Alquiler de Autos
        </button>
        <button className={activeTab === 'hotels' ? 'active' : ''} onClick={() => setActiveTab('hotels')}>
          <i className="fas fa-hotel"></i> Reserva de Hoteles
        </button>
        {/* <button className={activeTab === 'hotelTransport' ? 'active' : ''} onClick={() => setActiveTab('hotelTransport')}>
          <i className="fas fa-plane-departure"></i>Transporte + Hotel
        </button> */}
        <button className={activeTab === 'transfers' ? 'active' : ''} onClick={() => setActiveTab('transfers')}>
          <i className="fas fa-exchange-alt"></i> Traslados
        </button>
        <button className={activeTab === 'activities' ? 'active' : ''} onClick={() => setActiveTab('activities')}>
          <i className="fas fa-person-hiking"></i> Turismo en Venezuela 
        </button>
      </div>

      <form onSubmit={handleSubmit} className="reservation-form">
        {activeTab === 'flights' && <div><VuelosAereos></VuelosAereos></div>}
        {activeTab === 'carRentals' && <div><AlquilerAuto></AlquilerAuto></div>}
        {activeTab === 'hotels' && <div><ReservaHotel></ReservaHotel></div>}
        {/* {activeTab === 'hotelTransport' && <div>Contenido de Hotel + Transporte</div>} */}
        {activeTab === 'transfers' && <div><Traslados></Traslados></div>}
        {activeTab === 'activities' && <div><Turismos></Turismos></div>}
      </form>
    </div>
  );
};

export default ReservationForm;
