import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactForm.css';

const KeyCaptcha = "6LdGr0oqAAAAAJ2MFbZPzpptbdupR_Xy2pkZJPAi";


const ContactForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    email: '',
    solicitud: '',
  });

  const [errors, setErrors] = useState({
    email: '',
  });

  const [captchaValid, setCaptchaValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseType, setResponseType] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      if (!emailRegex.test(value)) {
        setErrors({ ...errors, email: 'Email no válido' });
      } else {
        setErrors({ ...errors, email: '' });
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptcha = (value) => {
    if (value) {
      setCaptchaValid(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaValid && !errors.email) {
      setIsSubmitting(true);
      try {
        const response = await axios.post('https://viajescabitours.com.ve/vuelos/Solicitud.php', formData, {
          headers: {
            'Authorization': 'd6b7146ca586d12bcb0606d2755fed6204b840ee1638650c2e9fb4638f9ca253e61021254db234edc10bdf31c28ceb947eaeed38c9f9646ac28c80af0731861f4887d0e958a3a328a306c8637eaba82e81218f7839267d5f60cbc9f7fc000709b5d999a74ffbc95f6529482bb38f03948fb8e917fe045f30d03d45d95dd1ec208232347b88e7d9d3f0d72060e1fc16d8283ea8dd2aedbe1cfeaf674983572c1fc9dfee2a325effdc66ff4a14eaae9c52f61d6f08467442a2b3aa27611e5f3aacd15540c3c97169b0f3b053f7ab4d5d867d506f6bb776cea814fb934d2fb2f0ba4386d646cf9808563efb1b963a4da41c6a3f8aa614f5e25b3962e7a3024abb7ab871486e86341470467fcd69e6fab58c6765bcc4837a2932176c5da00bfbb4d61056ddb5b167f8e339ba138bfedeef18b49c9f7c4f4e65423b1a1ad6800b347d2d60dcecf5cffeca45b4da3d9e39d4faeacbd82ee6f4ac2172ecde964cae3fddc0008a913835c20a5a91e62b15ee571f2c8ba71de59006101a90b1ee89e2d06850d35f945075515051c3fa540aa397e6778d40109ff486abee58d44b29ea4845ded01dbffb3a28531ffa4739aa01b9cda23c8e56965c58a0253ef0286f1051153edc82e0021f11ac1586580e4bf775b4486ba634a800738fb93f850ac64e526e853242f8d2ccab288149e66414bbc6b03244d27098dd7c9954749ef6e0ce86bf'
          }
        });
        if (response.data.status === 'success') {
          setResponseMessage('Gracias por enviar tu solicitud, te responderemos a la brevedad posible');
          setResponseType('success');
          setShowSuccessAlert(true);
        } else {
          setResponseMessage('Ocurrió un error, intenta de nuevo. Si el error persiste, puedes utilizar otros medios de contacto.');
          setResponseType('danger');
          setShowSuccessAlert(true);
        }
        setTimeout(() => {
          setShowSuccessAlert(false); // Ocultar la alerta de éxito ó error después de 3 segundos
        }, 3000);
      } catch (error) {
        setResponseMessage('Ocurrió un error, intenta de nuevo. Si el error persiste, puedes utilizar otros medios de contacto.');
        setResponseType('danger');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      alert('Por favor complete correctamente todos los campos.');
    }
  };

  const { nombre, telefono, email, solicitud } = formData;
  const isFormValid = nombre && telefono && email && solicitud && captchaValid && !errors.email;

  return (
    <div className="contact-form-ticket">
      <div className="ticket-header">
        <h2>Formulario de Contacto</h2>
      </div>
      <form onSubmit={handleSubmit} className="ticket-body">
        <div className="form-group">
          <label>Nombre</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Teléfono</label>
          <input
            type="tel"
            name="telefono"
            value={formData.telefono}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>
        <div className="form-group">
          <label>Solicitud</label>
          <textarea
            name="solicitud"
            rows="4"
            value={formData.solicitud}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey={KeyCaptcha}
            size="normal"
            onChange={handleCaptcha}
          />
        </div>
        <button type="submit" disabled={!isFormValid || isSubmitting}>
          Enviar
        </button>
        {responseMessage && showSuccessAlert && (
          <div className={`alert alert-${responseType} mt-3`} role="alert">
            {responseMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;