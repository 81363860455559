import React, { useState, useEffect } from "react";
import axios from "axios";
import './InstagramProfile.css';

const InstagramProfile = ({ accessToken, userId }) => {
  const [profile, setProfile] = useState(null);
  const [photos, setPhotos] = useState([]);
  const Token =  atob(accessToken)
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Obtener información del perfil
        const responseProfile = await axios.get(
          `https://graph.instagram.com/${userId}?fields=id,username,profile_picture_url&access_token=${Token}`
        );

        // Obtener las fotos publicadas (máximo 6)
        const responsePhotos = await axios.get(
          `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url,thumbnail_url,permalink&limit=6&access_token=${Token}`
        );

        setProfile({
          avatar: responseProfile.data.profile_picture_url, // Foto de perfil
          username: responseProfile.data.username,
          bio: "Agencia de Viajes Cabitours", // Bio no proporcionada directamente por la API
        });

        setPhotos(responsePhotos.data.data.slice(0, 3)); // Limitar a 6 fotos
      } catch (error) {
        console.error("Error fetching Instagram profile data:", error);
      }
    };

    fetchProfile();
  }, []);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="instagram-profile">
      <div className="header">
        <img className="avatar" src={profile.avatar} alt="Avatar" />
        <div className="user-info">
          <h2 className="username">{profile.username}</h2>
          <p className="bio">{profile.bio}</p>
        </div>
      </div>
      <div className="photos">
        {photos.map((photo) => (
          <a key={photo.id} href={photo.permalink} target="_blank" rel="noopener noreferrer">
            <img
              src={photo.media_type === 'VIDEO' ? photo.thumbnail_url : photo.media_url}
              alt="Instagram media"
              className="photo"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default InstagramProfile;
