import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import FeatureElement from './FeatureElement';
import './FeaturesSection.css';
import featuresData from '../data/features.json';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeaturesSection = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    // Cargar los datos del JSON en el estado
    setFeatures(featuresData.features);
  }, []);

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <section className="c-section-home c-section-home--features o-section o-section--top-small">
      <div className="o-container">
        <div className="o-row">
          <div className="o-col o-col--12">
          <h4 className="text-content-init color-text-init"><b>Características Destacadas</b></h4>
            {/* <h2 className="c-section-home__title">Características Destacadas</h2> */}
          </div>
        </div>
        <Slider {...settings2} className="o-row">
          {features.map((feature, index) => (
            <div key={index} className="o-col o-col--4">
              <FeatureElement 
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default FeaturesSection;