import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const TermsAndConditions = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container mt-5"  style={{ maxHeight: '530px', overflow: 'hidden', 'overflow-x': 'hidden' , 'overflow-y': 'scroll' }}>
      <div className="d-none d-md-block text-secondary text-justify">
        <h4>Términos y Condiciones</h4>
        <p class="font-container">Al enviar este formulario de contacto, usted acepta los siguientes términos y condiciones:</p>
        <div style={{ listStyleType: 'none', textAlign: 'justify' }}>
          <p><strong>Uso de la Información:</strong> La información proporcionada en este formulario será utilizada únicamente para responder a sus consultas y no será compartida con terceros sin su consentimiento previo.</p>
          <p><strong>Protección de Datos:</strong> Nos comprometemos a proteger su información personal. Todos los datos serán tratados de acuerdo con nuestra política de privacidad y las leyes aplicables de protección de datos.</p>
          <p><strong>Exactitud de la Información:</strong> Usted garantiza que la información proporcionada es precisa y completa. No nos hacemos responsables de cualquier error o inexactitud en la información suministrada.</p>
          <p><strong>Propósito del Contacto:</strong> Este formulario está destinado exclusivamente para consultas relacionadas con nuestros productos y servicios. No se permite el uso de este formulario para enviar contenido no solicitado o spam.</p>
          <p><strong>Respuesta a Consultas:</strong> Haremos todo lo posible para responder a sus consultas en un plazo razonable. Sin embargo, no garantizamos tiempos de respuesta específicos.</p>
          <p><strong>Modificaciones:</strong> Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Cualquier cambio será publicado en esta página.</p>
        </div>
      </div>

      <div className="d-block d-md-none">
        <Button variant="link" onClick={handleShow}>
          Términos y Condiciones
        </Button>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Términos y Condiciones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Al enviar este formulario de contacto, usted acepta los siguientes términos y condiciones:</p>
            <div style={{ listStyleType: 'none' }}>
              <p><strong>Uso de la Información:</strong> La información proporcionada en este formulario será utilizada únicamente para responder a sus consultas y no será compartida con terceros sin su consentimiento previo.</p>
              <p><strong>Protección de Datos:</strong> Nos comprometemos a proteger su información personal. Todos los datos serán tratados de acuerdo con nuestra política de privacidad y las leyes aplicables de protección de datos.</p>
              <p><strong>Exactitud de la Información:</strong> Usted garantiza que la información proporcionada es precisa y completa. No nos hacemos responsables de cualquier error o inexactitud en la información suministrada.</p>
              <p><strong>Propósito del Contacto:</strong> Este formulario está destinado exclusivamente para consultas relacionadas con nuestros productos y servicios. No se permite el uso de este formulario para enviar contenido no solicitado o spam.</p>
              <p><strong>Respuesta a Consultas:</strong> Haremos todo lo posible para responder a sus consultas en un plazo razonable. Sin embargo, no garantizamos tiempos de respuesta específicos.</p>
              <p><strong>Modificaciones:</strong> Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Cualquier cambio será publicado en esta página.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TermsAndConditions;
