import React from 'react';
import { FaCoffee, FaLock  } from 'react-icons/fa';
import { ImAirplane } from 'react-icons/im';
import { TfiWorld } from "react-icons/tfi";
import './FeatureElement.css';
import { FcAbout } from "react-icons/fc";
import { TbMapSearch, TbWorldHeart  } from "react-icons/tb";
import { GrContactInfo } from "react-icons/gr";

const iconMap = {
  FaCoffee: <FaCoffee />,
  Airplane: <ImAirplane />,
  World: <TfiWorld />,
  WorldHeart: <TbWorldHeart />,
  Lock: <FaLock />,
  GrContactInfo: <GrContactInfo />,
  Maps: <TbMapSearch/>
};

const FeatureElement = ({ title, description, icon }) => {
  return (
    <div className="c-features__element o-box u-flex--11 u-border--full bdr-clr--lighter-gray u-border-radius">
      <div className="feature-icon">{iconMap[icon]}</div>
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );
};

export default FeatureElement;
