import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './InstagramFeed.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'react-bootstrap/Button';

const InstagramFeed = ({ token, hashtag }) => {
  const [mediaItems, setMediaItems] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const Token =  atob(token)
  const Arrow = ({ className, style, onClick }) => (
    <Button style={{...style, left: 0, backgroundColor: 'red'}} onClick={onClick} className={className}>
      <div>back</div>
    </Button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    ArrowPrev:Arrow,
    responsive: [
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  useEffect(() => {

    const fetchInstagramMedia = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/v15.0/me/media?fields=id,media_type,media_url,thumbnail_url,timestamp,caption&access_token=${Token}`
        );
        
        // Filtrar por hashtag y ordenar por timestamp
        const filteredMedia = response.data.data
          .filter(media => 
            media.caption?.includes(`#${hashtag}`) && 
            (media.media_type === 'IMAGE' || media.media_type === 'VIDEO')
          )
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .slice(0, 10); // Limitar a un máximo de 10 elementos

        setMediaItems(filteredMedia);
      } catch (error) {
        console.error('Error fetching Instagram media:', error);
      }
    };

    fetchInstagramMedia();
  }, []);

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  return (
    <div className="instagram-feed-container">
        <h2 className="text-content-init color-text-init"><b>Paquetes Promocionales</b></h2>
        <p class="h4 text-content-init color-text-init">Explora nuevas experiencias de Viaje</p>
      {/* <div className="instagram-feed"> */}
      <Slider {...settings} className=" ">
        {mediaItems.map((media) => (
          <div key={media.id} className="media-container" onClick={() => handleMediaClick(media)}>
            {media.media_type === 'IMAGE' ? (
              <img src={media.media_url} alt={media.caption} />
            ) : media.media_type === 'VIDEO' ? (
              // <video src={media.media_url} controls>
              //   Your browser does not support the video tag.
              // </video>
              <img src={media.thumbnail_url	} alt={media.caption} />
            ) : null}
          </div>
        ))}      
        </Slider>
        {selectedMedia && (
          <div className="modal-insta" onClick={closeModal}>
            <div className="modal-content-new">
              {selectedMedia.media_type === 'IMAGE' ? (
                <img src={selectedMedia.media_url} alt={selectedMedia.caption} />
              ) : selectedMedia.media_type === 'VIDEO' ? (
                <video src={selectedMedia.media_url} controls autoPlay>
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          </div>
        )}
      {/* </div> */}
    </div>
  );
};

export default InstagramFeed;
