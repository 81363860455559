import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Modal, Button, Form, InputGroup, Row, Col, Card, Alert } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPlane, FaTrashAlt, FaUser, FaChild, FaPlus, FaMinus, FaList } from 'react-icons/fa';
import countries from '../data/countries.json';
import './Traslados.css';

const Traslados = () => {
  const [showModal, setShowModal] = useState(false);
  const urlApi = "https://viajescabitours.com.ve/vuelos/sendTraslados.php";
  const [showListModal, setShowListModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [formData, setFormData] = useState({
    tipoTraslado: '1',
    origen: '',
    destino: '',
    fechaEntrada: '',
    fechaSalida: '',
    pasajeros: { adultos: 0, ninos: 0 }
  });
  const [newDestino, setNewDestino] = useState({ origen: '', destino: '', fechaEntrada: '', fechaSalida: '' });
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showErrorAlert, setShowErrorAlert] = useState(false); 
  const tiposTraslado = [
    { key: "Solo ida", value: "1" },
    { key: "Ida y vuelta", value: "2" }
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePasajerosChange = (type, operation) => {
    setFormData((prevState) => {
      const newValue = operation === 'increment' ? prevState.pasajeros[type] + 1 : prevState.pasajeros[type] - 1;
      return {
        ...prevState,
        pasajeros: { ...prevState.pasajeros, [type]: Math.max(newValue, 0) }
      };
    });
  };
  const sendDataRequest = () => {
    setShowModal(false);
    const dataToSend = {
      ...formData,
      name: name,
      phone: phone,
      email: email
    };
    axios.post(urlApi, dataToSend)
      .then(response => {
        setShowSuccessAlert(true); // Mostrar la alerta de éxito
        setTimeout(() => {
          setShowSuccessAlert(false); // Ocultar la alerta de éxito después de 3 segundos
        }, 3000);
      })
      .catch(error => {
        setShowErrorAlert(true); // Mostrar la alerta de error
        setTimeout(() => {
          setShowErrorAlert(false); // Ocultar la alerta de error después de 3 segundos
        }, 3000);
      });
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };
  const handleSubmit = () => {
    setShowModal(true);
  };
  const isFormValid = () => {
    const { origen, destino, fechaEntrada, fechaSalida, pasajeros, tipoTraslado } = formData;
    if (tipoTraslado === '1') {
      return origen && destino && fechaEntrada && pasajeros.adultos > 0;
    } else {
      return origen && destino && fechaEntrada && fechaSalida && pasajeros.adultos > 0 && new Date(fechaSalida) >= new Date(fechaEntrada);
    }
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const handleCountrySearch = (e) => {
    const { name, value } = e.target;
    const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(filtered);
    setFormData({ ...formData, [name]: value });
  };
  const getFilteredCountries = (exclude) => {
    return countries.filter(country => country.name !== exclude);
  };
  const validateSelection = (e) => {
    const { name, value } = e.target;
    const isValid = countries.some(country => country.name === value);
    if (!isValid) {
      setFormData({ ...formData, [name]: '' });
    }
  };
  return (
    <div className="container mt-5">
      {showSuccessAlert && (
        <Alert variant="success" className="custom-alert">
          Solicitud enviada con éxito, responderemos a la brevedad posible.
        </Alert>
      )}
      {showErrorAlert && (
        <Alert variant="danger" className="custom-alert">
          Error al enviar la solicitud. Por favor, inténtelo de nuevo.
        </Alert>
      )}
      <div className="d-flex justify-content-between flex-column-700">
        <div>
          {tiposTraslado.map((tipo) => (
            <Form.Check
              inline
              key={tipo.value}
              type="radio"
              label={tipo.key}
              name="tipoTraslado"
              value={tipo.value}
              checked={formData.tipoTraslado === tipo.value}
              onChange={handleInputChange}
            />
          ))}
        </div>
      </div>
      <Row className="mt-3 flex-column-700">
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
              <Form.Control
                type="text"
                className="rounded-right"
                placeholder="Origen"
                name="origen"
                value={formData.origen}
                list="countryListOrigen"
                onBlur={validateSelection}
                onChange={handleCountrySearch} />
              <datalist id="countryListOrigen">
                {getFilteredCountries(formData.destino).map((country, index) => (
                  <option key={index} value={country.name} />
                ))}
              </datalist>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Destino"
                name="destino"
                value={formData.destino}
                list="countryListDestino"
                onBlur={validateSelection}
                onChange={handleCountrySearch} />
              <datalist id="countryListDestino">
                {getFilteredCountries(formData.origen).map((country, index) => (
                  <option key={index} value={country.name} />
                ))}
              </datalist>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 flex-column-700">
        <Col>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>Fecha de Entrada</InputGroup.Text>
              <Form.Control
                type="date"
                placeholder="Fecha de Entrada"
                name="fechaEntrada"
                value={formData.fechaEntrada}
                onChange={handleInputChange}
                min={currentDate}
                onKeyDown={(e) => e.preventDefault()}
                onClick={(e) => e.target.showPicker()} />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          {formData.tipoTraslado !== '1' && (
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>Fecha de Salida</InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Fecha de Salida"
                  name="fechaSalida"
                  value={formData.fechaSalida}
                  onChange={handleInputChange}
                  min={formData.fechaEntrada || currentDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()} />
                </InputGroup>
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row className="mt-3 flex-column-700">
          <Col>
            <InputGroup>
              <InputGroup.Text><FaUser /> Adultos</InputGroup.Text>
              <InputGroup.Text>
                <FaMinus onClick={() => handlePasajerosChange('adultos', 'decrement')} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Adultos"
                name="adultos"
                value={formData.pasajeros.adultos}
                onChange={handlePasajerosChange}
                min="1"
              />
              <InputGroup.Text>
                <FaPlus onClick={() => handlePasajerosChange('adultos', 'increment')} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text><FaChild />Niños</InputGroup.Text>
              <InputGroup.Text>
                <FaMinus onClick={() => handlePasajerosChange('ninos', 'decrement')} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Niños"
                name="ninos"
                value={formData.pasajeros.ninos}
                onChange={handlePasajerosChange}
                min="0"
              />
              <InputGroup.Text>
                <FaPlus onClick={() => handlePasajerosChange('ninos', 'increment')} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <Button
          variant="primary"
          className="mt-3"
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          Solicitar Cotización
        </Button>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Solicitar Cotización</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese su nombre"
                value={name}
                onChange={handleNameChange}
                isInvalid={name === ''}
              />
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese su email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!isEmailValid && email !== ''}
              />
              <Form.Control.Feedback type="invalid">
                Por favor, ingrese un email válido.
              </Form.Control.Feedback>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Ingrese su teléfono"
                value={phone}
                onChange={handlePhoneChange}
                isInvalid={phone === ''}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cerrar
            </Button>
            <Button
              onClick={sendDataRequest}
              disabled={!isEmailValid || email === '' || phone === '' || name === ''}
              variant="primary"
            >
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  
  export default Traslados;