import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Modal, Button, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';
import countries from '../data/countries.json';
import './AlquilerAuto.css';

const AlquilerAuto = () => {
  const [showModal, setShowModal] = useState(false);
  const urlApi = "https://viajescabitours.com.ve/vuelos/sendAlquiler.php";
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [formData, setFormData] = useState({
    origen: '',
    devolucion: '',
    fechaRecogida: '',
    horaRecogida: '',
    fechaEntrega: '',
    horaEntrega: '',
    nacionalidad: 'Venezuela',
    mismaOficina: false,
    conductorEdad: false
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showErrorAlert, setShowErrorAlert] = useState(false); 
  const [filteredCountries, setFilteredCountries] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: val });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value));
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleSubmit = () => {
    setShowModal(true);
  };

  const sendDataRequest = () => {
    setShowModal(false);
    const dataToSend = {
      ...formData,
      name: name,
      phone: phone,
      email: email
    };
    axios.post(urlApi, dataToSend)
      .then(response => {
        console.log(response.data);
        setShowSuccessAlert(true); // Mostrar la alerta de éxito
        setTimeout(() => {
          setShowSuccessAlert(false); // Ocultar la alerta de éxito después de 3 segundos
        }, 3000);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        setShowErrorAlert(true); // Mostrar la alerta de error
        setTimeout(() => {
          setShowErrorAlert(false); // Ocultar la alerta de error después de 3 segundos
        }, 3000);
      });
  };

  const isFormValid = () => {
    const { origen, devolucion, fechaRecogida, horaRecogida, fechaEntrega, horaEntrega, nacionalidad, mismaOficina, conductorEdad } = formData;
    return origen && (mismaOficina || devolucion) && fechaRecogida && horaRecogida && fechaEntrega && horaEntrega && nacionalidad && conductorEdad;
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleCountrySearch = (e) => {
    const { name, value } = e.target;
    const filtered = countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredCountries(filtered);
    setFormData({ ...formData, [name]: value });
  };

  const validateSelection = (e) => {
    const { name, value } = e.target;
    const isValid = countries.some(country => country.name === value);
    if (!isValid) {
      setFormData({ ...formData, [name]: '' });
    }
  };

  useEffect(() => {
    if (formData.mismaOficina) {
      setFormData((prevState) => ({
        ...prevState,
        devolucion: prevState.origen
      }));
    }
  }, [formData.mismaOficina, formData.origen]);

  return (
    <div className="container mt-5">
      {showSuccessAlert && (
        <Alert variant="success" className="custom-alert">
          Solicitud enviada con éxito, responderemos a la brevedad posible.
        </Alert>
      )}
      {showErrorAlert && (
        <Alert variant="danger" className="custom-alert">
          Error al enviar la solicitud. Por favor, inténtelo de nuevo.
        </Alert>
      )}
      <Form>
        <Row className="align-items-center">
          <Col className="col-12 col-md-auto">
            <Form.Check
              type="checkbox"
              label="Misma oficina de devolución"
              name="mismaOficina"
              checked={formData.mismaOficina}
              onChange={handleInputChange}
              className="checkbox-label"
            />
          </Col>
          <Col className="col-12 col-md-auto">
            <Form.Check
              type="checkbox"
              label="Conductor entre 30 y 65 años"
              name="conductorEdad"
              checked={formData.conductorEdad}
              onChange={handleInputChange}
              className="checkbox-label"
            />
          </Col>
          <Col className="col-12 col-md">
            <Form.Group>
              <Form.Label>Nacionalidad</Form.Label>
              <Form.Select
                name="nacionalidad"
                value={formData.nacionalidad}
                onChange={handleInputChange}
              >
                <option value="Venezuela">Venezuela</option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>{country.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-12 col-md">
            <Form.Group>
              <Form.Label>Origen</Form.Label>
              <InputGroup>
                <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Origen"
                  name="origen"
                  value={formData.origen}
                  list="countryListOrigen"
                  onBlur={validateSelection}
                  onChange={handleCountrySearch}
                />
                <datalist id="countryListOrigen">
                  {filteredCountries.map((country, index) => (
                    <option key={index} value={country.name} />
                  ))}
                </datalist>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col className="col-12 col-md">
            <Form.Group>
              <Form.Label>Devolución</Form.Label>
              <InputGroup>
                <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Devolución"
                  name="devolucion"
                  value={formData.devolucion}
                  list="countryListDevolucion"
                  onBlur={validateSelection}
                  onChange={handleCountrySearch}
                  disabled={formData.mismaOficina}
                />
                <datalist id="countryListDevolucion">
                  {filteredCountries.map((country, index) => (
                    <option key={index} value={country.name} />
                  ))}
                </datalist>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-12 col-md">
            <Form.Group>
              <Form.Label>Fecha y Hora de Recogida</Form.Label>
              <InputGroup>
                <Form.Control
                  type="date"
                  name="fechaRecogida"
                  value={formData.fechaRecogida}
                  onChange={handleInputChange}
                  min={currentDate}
                  onClick={(e) => e.target.showPicker()}
                />
                <Form.Control
                  type="time"
                  name="horaRecogida"
                  value={formData.horaRecogida}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col className="col-12 col-md">
            <Form.Group>
              <Form.Label>Fecha y Hora de Entrega</Form.Label>
              <InputGroup>
                <Form.Control
                  type="date"
                  name="fechaEntrega"
                  value={formData.fechaEntrega}
                  onChange={handleInputChange}
                  min={formData.fechaRecogida}
                  onClick={(e) => e.target.showPicker()}
                />
                <Form.Control
                  type="time"
                  name="horaEntrega"
                  value={formData.horaEntrega}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          className="mt-3"
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
            Solicitar Cotización
      </Button>
    </Form>
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Solicitar Cotización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese su nombre"
            value={name}
            onChange={handleNameChange}
            isInvalid={name === ''}
          />
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingrese su email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!isEmailValid && email !== ''}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un email válido.
          </Form.Control.Feedback>
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
            type="phone"
            placeholder="Ingrese su teléfono"
            value={phone}
            onChange={handlePhoneChange}
            isInvalid={phone === ''}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cerrar
        </Button>
        <Button
          onClick={sendDataRequest}
          disabled={!isEmailValid || email === '' || phone === '' || name === ''}
          variant="primary"
        >
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);
};

export default AlquilerAuto;